import React from "react";
import "./hero.css";

interface Props {
  refProp: React.RefObject<HTMLDivElement>;
  setIsCheckoutPopupOpen: (b: boolean) => void;
}

export default function Hero({ refProp, setIsCheckoutPopupOpen }: Props) {
  return (
    <div className="hero" id="about" ref={refProp}>
      <div className="hero1">
        <img
          className="fox"
          src={require("../../assets/XVA Fox 1.png")}
          alt=""
        />
        <div className="text">
          <div className="section-1">
            <div className="heading-1">BREAKING NEWS!</div>
            <div className="heading-2">
              We are pleased to announce that we will soon be listing on{" "}
              <a href=" http://XT.com">XT.com</a>
              <br />
            </div>
            <br />
            {/*<button onClick={() => setIsCheckoutPopupOpen(true)}>
              BUY $SPLRG NOW
            </button>*/}
            <br />
            <br />
            Hit the button above and purchase $SPLRG
            <br />
            Each SPLRG token is USD 0.8, min spend is 2◎ and the max spend is
            20◎
            <br />
            <br />
            Pass us your wallet address and $SPLRG will come knocking on your
            wallet door within a week
            <br />
            <img src={require("../../assets/chart.png")} alt="" />
          </div>
          <div className="heading">
            <div className="heading2">
              What’s $SPLRG, you ask?
              <br />
              we’re the coin
              <br />
              with a meme twist.
              <br />
            </div>
            <img
              className="diamond-line"
              src={require("../../assets/Diamond Arrow Down.png")}
              alt=""
            />
          </div>
          <div className="section">
            We’re droppin’ something big: the first-ever luxury- focused Web3
            currency. Yeah, you heard that right. We’re talkin’ exclusive 1 of 1
            tradeable luxury asset backed NFTs <br /> <br />
            And a whole marketplace on SOL dedicated to that. Real World Assets,
            baby! Need more? How ‘bout a sweet Mastercard deal that’ll score you
            access to the hottest gigs and tokenizin’ a mil’ worth of top-shelf
            wines? And guess what? We’re just gettin’ warmed up. Brace yourself.
            We're talkin' a whole range of top-tier real-world assets, all
            tokenized as NFTs on our XVA Collective marketplace that will be
            launched soon.
            <br /> <br />
            Now, you might think this sounds like bullcrap, and yeah, maybe it
            does. But who knows? <br /> <br />
            We might just surprise ya. ’Cause remember, we’re just a meme
            coin... until we’re not.
            <br />
          </div>
        </div>
      </div>
      <div className="hero2">
        <div className="section">
          So, I strongly suggest splurge a little and join my mates in the
          $SPLRG community. <br />
          <br />
          Soon, you'll be tradin' these gems on a bunch of crypto exchanges
          worldwide. Remember, Luxury is the new Currency in this Web3
          neighbourhood we call home innit?
          {/* <br /> <br />
          Now we have a few tantalising offers for our first 100 OG. */}
          <br />
        </div>

        <div className="section">
          We have some fantastic incentives for you when you purchase our $SPLRG
          tokens! <br />
          <br />
          Buy 3 SOL worth of $SPLRG tokens and receive a limited edition MOJOKO
          print.{" "}
          <a href="https://x.com/xva_collective/status/1795310790989492369">
            Learn More
          </a>{" "}
          <br /> Buy 10 SOL worth of $SPLRG tokens and get a premium wine NFT.{" "}
          <br />
          <a href="https://x.com/xva_collective/status/1800866734238560263">
            Learn More
          </a>{" "}
          <br />
          Limited numbers available.
          <br />
          <br />
          Snag it now, and we'll drop it straight into your wallet within 2
          weeks.
          <br />
          {/* And here's the kicker: Once you claim it, your NFT—exclusive to our
          first 100 OG buyers—will be active for us to drop some cool and
          unexpected RWA into it. <br />
          You'll never know what you're gonna get! Don't miss out! */}
        </div>

        <div className="section">
          {/* Alright, gather round, you fresh faces diving into $SPLRG! Ready to
          make waves? <br /> <br /> To celebrate our official entry into the RWA
          world, we've cooked up 2 offers that's turning heads among our
          partners! <br /> <br />
          Here's the scoop:
          <br />
          Buy 2 SOL of $SPLRG coins
          <br />
          Bag yourself our exclusive $SPLRG NFT
          <br />
          But hold up—there are only 100 NFTs up for grabs. An NFT featuring our
          slick Fox motif. It's the epitome of style and swagger, just like us!
          <br />
          Snag it now, and we'll drop it straight into your wallet within 2
          weeks.
          <br />
          And here's the kicker: Once you claim it, your NFT—exclusive to our
          first 100 OG buyers—will be active for us to drop some cool and
          unexpected RWA into it. You'll never know what you're gonna get!
          Today, it's the comfiest tee. Tomorrow? Well, that's a surprise!{" "}
          <br />
          <img
            src={require("../../assets/tshirt.png")}
            alt=""
            className="shirt"
          />
          <br />
          ORRRRR
          <br /> <br />l Buy 3 SOL of $SPLRG coins and you'll receive a $SPLRG
          NFT packed with even more goodies. <br />
          You'll get the limited edition $SPLRG tee
          <br />
          AND a signed and numbered limited edition print from the renowned
          master of Pop <br />
          Art Surrealism – MOJOKO. <br />
          Only 15 pieces available!
          <br />
          These are all part of the special first 100 OG NFT offer
          <br />
          <img
            src={require("../../assets/street.jpeg")}
            alt=""
            className="shirt"
          />
          <div className="credit"> Toyshop by MOJOKO</div>
          <br /> */}
          Here's a pro tip: Why not buy 2? Rock one and stash the other as a
          collectible. Trust me, $SPLRG is on the up, and we want you along for
          the ride. <br /> <br /> Some points to note:
          <br />
          To claim, fill up your address and size in the form found in the NFT.
          <br />
          Once claimed, your NFT is still active for more drops or to trade!.
          <br />
          We will drop the $SPLRG coins and NFTs in 2 weeks.
        </div>

        <div className="section">
          For a quick summary of what $SPLRG is all about{" "}
          <a href={window.location.href + "info1.pdf"}>click here</a>
          <br />
          For a more detailed White Paper on $SPLRG{" "}
          <a href={window.location.href + "info2.pdf"}>click here</a>
          <br />
          <br />
          One last step. We've got plans constantly brewin', mate. Stay tuned on
          our socials for the lowdown.
        </div>
        <img
          className="fox-meme"
          src={require("../../assets/XVA Fox Meme 1.png")}
          alt=""
        />
      </div>
    </div>
  );
}
